html {
    position: relative;
    min-height: 100%;
}

body {
    /* Margin bottom by footer height */
    margin-bottom: 130px;
}

.footer {
    padding-top: 10px;
    position: absolute;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 130px;
}

.tbc-footer-text {
    font-size: 14px;
}