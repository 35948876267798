.gallery-image-wrapper {
    max-width: 275px;
}

.gallery-image {
    width: 275px;
    height: 175px;
}

.cursor-pointer {
    cursor: pointer;
}