a.nav-link {
    text-transform: uppercase;
}

.highlight {
    background-color: yellow;
}

.bottom-padding {
    padding-bottom: 10px;
}